import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import { FormattedMessage } from "react-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BioDataContentPropType from "../utils/bio-content-proptype"

const Initiative = ({ data }) => {
  const {
    contentfulBio: {
      text: {
        // text,
        childMarkdownRemark: { html },
      },
    },
  } = data
  return (
    <Layout>
      <SEO title="Initiative" />
      <div
        css={css`
          width: 90%;
          margin: 30px auto;
          font-size: 17px;
          font-weight: 500;
        `}
      >
        <div
          css={css`
            margin: 0px 0px;
            flex: 1;
            font-size: 16px;
            color: #f8f2f2;

            p {
              margin-top: 0px;
            }

            p:lang(ar) {
              text-align: right;
              font-size: 15px;
              line-height: 1.5rem;
            }
          `}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div
          css={css`
            font-size: 17px;
            font-weight: 700;
            color: rgba(236, 223, 227, 0.6);

            a {
              text-decoration: none;
            }
          `}
        >
          <a href="https://secure.squarespace.com/checkout/donate?donatePageId=5bc627f90852298e0d320f3d">
            <FormattedMessage id="initiative.donate" />
          </a>
        </div>
      </div>
    </Layout>
  )
}

Initiative.propTypes = BioDataContentPropType

export const query = graphql`
  query initiative($lang: String = "en-US") {
    contentfulBio(bioId: { eq: "initiative" }, node_locale: { eq: $lang }) {
      text {
        text
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Initiative
